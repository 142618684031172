<script lang="ts">
  import { Input, Dropdown, DropdownItem } from "flowbite-svelte";
  import logo from "$lib/images/BBIA.png";
  import { Button } from "flowbite-svelte";
  import {
    UserSolid,
    LanguageOutline,
    ChevronDownOutline,
    FileChartBarSolid,
  } from "flowbite-svelte-icons";
  import { onMount, createEventDispatcher } from "svelte";
  import { user } from "$lib/stores";
  import type { Ressource } from "$lib/stores";

  let ctrlKey = "Ctrl";
  $: search = "";

  onMount(() => {
    if (navigator.platform.indexOf("Mac") > -1) {
      ctrlKey = "⌘";
    }
  });

  export let ressourceId: string = "";

  $: items = [
    { name: "Home", link: "/" + ressourceId + "/" },
    { name: "Dashboard", link: "/" + ressourceId + "/dashboard" },
    { name: "Services", link: "/" + ressourceId + "/services" },
    { name: "Teams", link: "/" + ressourceId + "/teams" },
    { name: "Vendors", link: "/" + ressourceId + "/vendors" },
    { name: "Activities", link: "/" + ressourceId + "/activities" },
    { name: "Ressources", link: "/ressources" },
    { name: "Me", link: "/me" },
  ];
</script>

<header
  class="border-b p-4 border-gray-300 flex justify-between items-center bg-white dark:bg-gray-800"
>
  <div class="flex items-center">
    <a href="/ressources" class="flex items-center space-x-2">
      <img src={logo} alt="logo" class="h-10 w-10 object-contain" />
      <span class="text-2xl font-semibold text-gray-800 dark:text-gray-200"
        >BBIA</span
      >
    </a>
  </div>

  <div class="relative w-1/3">
    <Input
      type="text"
      id="search"
      placeholder="Search In App ({ctrlKey} + K)"
      class="w-full bg-gray-50 border border-gray-300"
      bind:value={search}
    />
    {#if search.length > 0}
      <div
        class="absolute top-full left-0 w-full mt-2 bg-white border border-gray-300 rounded shadow-lg z-10"
      >
        {#each items.filter((item) => item.name
            .toLowerCase()
            .includes(search.toLowerCase())) as item}
          <a
            href={item.link}
            on:click={() => (search = "")}
            class="block px-4 py-2 hover:bg-gray-100 border-b border-gray-200 flex justify-between"
          >
            <span>{item.name}</span>
            <ChevronDownOutline class="w-5 h-5" />
          </a>
        {/each}
      </div>
    {/if}
  </div>

  <div class="flex items-center space-x-4">
    <Button
      color="light"
      size="sm"
      href="/login"
      outline={!!$user?.name}
      class="border-gray-300"
    >
      <UserSolid class="w-5 h-5" />
      <span>{$user?.name ?? "Login"}</span>
    </Button>
    <Button color="dark" size="sm" class="border-gray-300">
      <LanguageOutline class="w-5 h-5" /> English
    </Button>
  </div>
</header>

<style>
  header {
    font-family: "Inter", sans-serif;
  }
  a:hover {
    text-decoration: none;
  }
  .nav {
    --background: rgba(255, 255, 255, 0.9);
  }
</style>
