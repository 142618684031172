<script lang="ts">
  import {
    Sidebar,
    SidebarWrapper,
    SidebarItem,
    SidebarGroup,
    Button,
    Dropdown,
    DropdownItem,
    Modal,
  } from "flowbite-svelte";
  import {
    BarsOutline,
    GridSolid,
    DatabaseSolid,
    FileChartBarSolid,
    LayersSolid,
    StoreSolid,
    UsersGroupSolid,
    CashSolid,
    UserSettingsSolid,
    CloseOutline,
    ChevronDownOutline,
    ChevronRightOutline,
    UserOutline,
  } from "flowbite-svelte-icons";
  import { createEventDispatcher, onMount } from "svelte";
  import { page } from "$app/stores";
  import type { RessourceFull } from "$lib/types/entities/ressource.entity";

  $: path = "";
  onMount(() => {
    path = window.location.pathname;
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1024) {
        mobileView = true;
      } else {
        mobileView = false;
      }
    });
    if (window.innerWidth < 1024) {
      mobileView = true;
    } else {
      mobileView = false;
    }
  });

  $: activeUrl = $page.url.pathname;
  $: mobileView = true;

  export let ressources: RessourceFull[];

  let dropdown = false;
  let selectedIndex = 0;

  const dispatch = createEventDispatcher();

  function handleRessourceChange(index: number) {
    dispatch("ressourceChange", index);
  }

  $: ressourceId =
    ressources && ressources[0] ? ressources[selectedIndex]?.id : 0;
</script>

<div>
  <Sidebar
    {activeUrl}
    asideClass="bg-white dark:bg-gray-900 text-gray-900 dark:text-white border-r border-b border-gray-200 dark:border-gray-800 invisible md:visible h-0 md:h-full"
  >
    <SidebarWrapper
      divClass="overflow-y-auto py-4 px-3 bg-gray-50 dark:bg-gray-800 h-full"
    >
      <div class=" pb-2" style="transform:translateX(-5px)">
        <Button
          color="light"
          size="sm"
          class="flex items-center space-x-2 border-gray-300"
          on:click={() => (dropdown = !dropdown)}
          block
        >
          <FileChartBarSolid class="w-5 h-5" />
          <span class="text-sm font-semibold">
            {#if ressources.length > 0}
              {ressources[selectedIndex].name}
            {:else}
              ...
            {/if}
          </span>
        </Button>
      </div>
      <SidebarGroup>
        <SidebarItem
          href="/{ressourceId}/dashboard/"
          activeClass="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white"
        >
          <svelte:fragment slot="icon">
            <GridSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
          <svelte:fragment slot="subtext">
            {#if activeUrl.includes("dashboard")}
              <span class="font-semibold">Dashboard</span>
            {:else}
              Dashboard
            {/if}
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/activities/">
          <svelte:fragment slot="icon">
            <DatabaseSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
          <svelte:fragment slot="subtext">
            {#if activeUrl.includes("activities")}
              <span class="font-semibold">Activities</span>
            {:else}
              Activities
            {/if}
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/services/">
          <svelte:fragment slot="icon">
            <LayersSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
          <svelte:fragment slot="subtext">
            {#if activeUrl.includes("services")}
              <span class="font-semibold">Services</span>
            {:else}
              Services
            {/if}
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/vendors/">
          <svelte:fragment slot="icon">
            <StoreSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
          <svelte:fragment slot="subtext">
            {#if activeUrl.includes("vendors")}
              <span class="font-semibold">Vendors</span>
            {:else}
              Vendors
            {/if}
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/teams/">
          <svelte:fragment slot="icon">
            <UsersGroupSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
          <svelte:fragment slot="subtext">
            {#if activeUrl.includes("teams")}
              <span class="font-semibold">Teams</span>
            {:else}
              Teams
            {/if}
          </svelte:fragment>
        </SidebarItem>
      </SidebarGroup>
      <SidebarGroup border>
        <SidebarItem label="Ressources" href="/ressources">
          <svelte:fragment slot="icon">
            <FileChartBarSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem label="Me" href="/me">
          <svelte:fragment slot="icon">
            <UserSettingsSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
      </SidebarGroup>
    </SidebarWrapper>
  </Sidebar>
  <Sidebar
    {activeUrl}
    asideClass="bg-white dark:bg-gray-900 text-gray-900 dark:text-white border-r border-b border-gray-200 dark:border-gray-800 h-full visible md:invisible"
  >
    <SidebarWrapper
      divClass="overflow-y-auto py-4 px-3 bg-gray-50 dark:bg-gray-800 h-full"
    >
      {#if ressources.length > 0}
        <div class="px-" style="transform:translateX(-5px)">
          <Button
            color="blue"
            size="sm"
            class="flex items-center space-x-2 border-gray-300"
            on:click={() => (dropdown = !dropdown)}
            outline
          >
            <ChevronDownOutline class="w-5 h-5" />
          </Button>
        </div>
      {/if}
      <SidebarGroup>
        <SidebarItem href="/{ressourceId}/dashboard">
          <svelte:fragment slot="icon">
            <GridSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/activities">
          <svelte:fragment slot="icon">
            <DatabaseSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/services">
          <svelte:fragment slot="icon">
            <LayersSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/vendors">
          <svelte:fragment slot="icon">
            <StoreSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/{ressourceId}/teams">
          <svelte:fragment slot="icon">
            <UsersGroupSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
      </SidebarGroup>
      <SidebarGroup border>
        <SidebarItem href="/ressources">
          <svelte:fragment slot="icon">
            <FileChartBarSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
        <SidebarItem href="/me">
          <svelte:fragment slot="icon">
            <UserSettingsSolid
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            />
          </svelte:fragment>
        </SidebarItem>
      </SidebarGroup>
    </SidebarWrapper>
  </Sidebar>
  <Modal
    open={dropdown}
    on:close={() => (dropdown = false)}
    title="Select Ressource"
  >
    <div class="">
      {#each ressources as ressource, index}
        <div class="flex items-center justify-between border-b py-2">
          <div class="flex items-start space-x-2">
            <p>
              <strong>
                {ressource.name}
              </strong>
              |
              {ressource.description.length > 0 ? ressource.description : "..."}
            </p>
            <div class="flex items-center">
              | {ressource.members.length}
              <UserOutline class="w-4 h-4" />
            </div>
          </div>
          {#if selectedIndex === index}
            <Button color="blue" size="sm" disabled>
              Already In
              <ChevronRightOutline class="w-4 h-4" />
            </Button>
          {:else}
            <Button
              color="blue"
              size="sm"
              outline
              on:click={() => {
                selectedIndex = index;
                handleRessourceChange(index);
                dropdown = false;
              }}
            >
              Go to
              <ChevronRightOutline class="w-4 h-4" />
            </Button>
          {/if}
        </div>
      {/each}
    </div>
  </Modal>
</div>
